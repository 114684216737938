.title_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22vh;
    background: linear-gradient(135deg, #fafafa, #ffffff);
    text-align: center;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Enhanced glass effect */
.title_section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.1);
    z-index: 0;
}

.title.modern_title {
    font-size: clamp(1.5rem, 4vw, 2.75rem);
    font-weight: 700;
    background: black;
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    z-index: 1;
    letter-spacing: -0.03em;
    animation: shine 8s linear infinite;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
    padding-bottom: 0.5rem;
    transition: transform 0.3s ease;
}

/* Dot before underline */
.title.modern_title::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 6px;
    height: 6px;
    background: black;
    border-radius: 50%;
    transform: translateX(-50px);
    transition: transform 0.3s ease;
}


/* Underline after dot */
.title.modern_title::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-40px);
    width: 100px;
    height: 6px;
    border-radius: 3px;
    background: black;
    transition: width 0.3s ease;
}

.title.modern_title:hover {
    transform: translateY(-2px);
}

.title.modern_title:hover::before {
    transform: translateX(-40px);
}

.title.modern_title:hover::after {
    width: 80px;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

@media (max-width: 768px) {
    .title_section {
        height: 18vh;
        padding: 1rem;
    }
    
    .title.modern_title {
        font-size: clamp(1.25rem, 3.5vw, 2rem);
    }
    
    .title.modern_title::before {
        transform: translateX(-30px);
    }
    
    .title.modern_title::after {
        width: 40px;
        transform: translateX(-20px);
    }
    
    .title.modern_title:hover::before {
        transform: translateX(-25px);
    }
}