.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.login-card {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: transform 0.3s ease;
}

.login-card:hover {
    transform: translateY(-5px);
}

.login-title {
    text-align: center;
    color: #183D3D;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #333;
    font-size: 0.9rem;
}

.form-group input {
    padding: 0.75rem;
    border: 2px solid #cccccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    outline: none;
    border-color: #183D3D;
}

.login-button {
    background-color: #183D3D;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #2c5d5d;
}

.login-logo {
    display: block;
    margin: 0 auto 30px;
    width: 120px; /* Adjust the width as needed */
}

@media (max-width: 768px) {
    .login-card {
        margin: 1rem;
        padding: 1.5rem;
    }
}