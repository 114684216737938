.carousel-indicators {
    margin-bottom: 0 !important;
}

.landing_services {
    position: absolute;
    display: flex;
    justify-content: space-around;
    top: 8rem;
    z-index: 100;
}

.landing_services_div {
    background-color: #ffffffe1;
    width: 18rem;
    line-height: 2;
    padding: 1rem;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.landing_services_title {
    color: #3498DB;
    font-size: 1.5rem;
    font-weight: bold;
}

.landing_services_points {
    font-size: 0.8rem;
    list-style-type: circle;
}

.arrows {
    width: 30px;
    height: 36px;
    margin-bottom: 1rem;
}

.arrows path {
    stroke: red;
    fill: transparent;
    stroke-width: 3px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
    {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

.arrows path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s;
    /* Safari 和 Chrome */
}

.arrows path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s;
    /* Safari 和 Chrome */
}

.arrows path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    /* Safari 和 Chrome */
}

.landing_bg {
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: auto;
}

.landing_bg_scroll {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: space-around;
    left: 50%;
}

.landing_bg_mercImage {
    /* border-radius: 30px 30px 0 0; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.landing_bg_header {
    margin: 0 8rem;
    font-size: 5rem;
    font-family: Titillium Web, sans-serif;
    color: #FFFFFF;
}

.landing_bg_sub_header {
    color: #FEBD09;
    font-size: 2.5rem !important;
}

.landing_bg_info {
    background-color: #0000007B;
    margin: 0 8rem;
    line-height: 1.8;
    font-size: 1.5rem;
    font-family: Titillium Web, sans-serif;
    font-style: normal;
    color: #FFFFFF;
}

.landing_bg_services {
    margin: 0 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing_bg_services_stack {
    width: 18%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.landing_bg_services_stack_button {
    background-color: #00000000;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    width: 15rem;
    text-align: center;
    font-size: 1rem;
    padding: 1rem;
    /* border-radius: 0.6rem; */
    cursor: pointer;
    animation: blink 4s infinite;
}

@keyframes blink {
    50% {
        border: 2px solid #00000000;
    }
}

.landing_bg_services_stack_button:hover {
    background-color: #ffffffe1;
    border: 2px solid #000000;
    width: 18rem;
    color: red;
    font-weight: bold;
    animation: none;
}

.landing_qt_images {
    margin: 3rem 8rem 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing_qt_images_style {
    width: 20%;
}

.landing_info_table {
    margin: 1rem 4rem;
    padding: 2rem;
    display: flex;
    align-items: start;
}

.landing_info_table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    align-items: start;
}

.landing_info_table_info {
    margin: 5rem auto;
    /* Changed margin-top to margin and added auto for horizontal centering */
    padding: 3rem;
    text-align: justify;
    width: 80%;
    font-weight: 300;
    justify-content: center;
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    border-radius: 20px;
    box-shadow:
        0 8px 20px rgba(0, 0, 0, 0.1),
        0 4px 12px rgba(0, 0, 0, 0.06),
        inset 0 -1px 4px rgba(0, 0, 0, 0.04);
    transform: perspective(1500px) rotateX(3deg) translateY(0);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12px);
    position: relative;
    overflow: hidden;
    line-height: 1.5rem;
    font-size: 1.1rem;
}

.landing_info_table_info:hover {
    transform: perspective(1500px) rotateX(0deg) translateY(-10px);
    box-shadow:
        0 15px 30px rgba(0, 0, 0, 0.15),
        0 10px 20px rgba(0, 0, 0, 0.1),
        inset 0 -2px 6px rgba(0, 0, 0, 0.07);
    background: linear-gradient(135deg, #ffffff 0%, #eef1f5 100%);
}

.landing_info_table_info::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.25), transparent);
    transition: transform 0.5s ease-in-out;
    transform: scaleX(0);
    z-index: 1;
}

.landing_info_table_info:hover::after {
    transform: scaleX(1);
    left: 0;
}

.landing_info_table_info_para {
    line-height: 3rem;
    font-size: 1.3rem;
    color: #444;
    /* Better contrast for readability */
}

.landing_info_table_info:hover .landing_info_table_info_para {
    color: #222;
    /* Slightly darker color for hover effect */
}


.landing_info_table_line {
    height: 25rem;
    width: 1px;
    border-left: 1px solid #000000;
}

.landing_blocks {
    margin: 5rem 8rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.landing_blocks_block {
    width: 40%;
    margin-top: 2rem;
    padding: 3rem 2rem;
    /* background-color: #D4E6F1; */
    border-radius: 1rem;
    border: 1px solid #f5f5f5;
    box-shadow: 5px 5px 2.5px 6px #6771764f;
}

.landing_blocks_block_title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.landing_blocks_block_info {
    font-size: 1.5rem;
}

.landing_customers_grid {
    margin: 3rem 8rem 1rem 8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.landing_customers_grid_block_img {
    width: 100%;
    margin-bottom: 1rem;
}

.landing_customers_more {
    display: inline-block;
    padding: 12px 24px;
    margin: 2rem 8rem;
    background-color: #8a2be2;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(138, 43, 226, 0.2);
}

.landing_customers_more:hover {
    background-color: #7209b7;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(138, 43, 226, 0.3);
}

.landing_customers_more:active {
    transform: translateY(0);
    box-shadow: 0 2px 10px rgba(138, 43, 226, 0.2);
}

@keyframes blink-card {
    0% {
        box-shadow: 0 0 25px #113946;
    }

    50% {
        box-shadow: none;
    }

    100% {
        box-shadow: 0 0 25px #113946;
    }
}

@-webkit-keyframes blink-card {
    0% {
        box-shadow: 0 0 25px #113946;
    }

    50% {
        box-shadow: 0 0 0;
    }

    100% {
        box-shadow: 0 0 25px #113946;
    }
}

.flip-card {
    background-color: transparent;
    width: calc(50%);
    min-width: 300px;
    height: 14rem;
    perspective: 1500px;
    align-items: center;
    margin: 1rem;
    /* Reduced from 1rem */
    transition: all 0.3s ease;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transform-style: preserve-3d;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg) scale(1.02);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 2rem;
    border-radius: 16px;
    transition: all 0.4s ease;
}

.flip-card-front {
    background: blacklinear-gradient(145deg, #ffffff, #f5f5f5);
    color: #2c3e50;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.flip-card-back {
    background: blacklinear-gradient(145deg, #ffffff, #f5f5f5);
    color: #ffffff;
    transform: rotateY(180deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.landing_background_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 3rem 0;
}

.landing_cards {
    max-width: 1300px;
    margin: 3rem auto;
    padding: 0 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    justify-content: center;
    text-align: left;
    background-color: #fff;
    border-radius: 15px;
    box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.05),
        0 6px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 2rem;
}

.landing_cards_menu_img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.flip-card:hover .landing_cards_menu_img {
    transform: scale(1.15) rotate(5deg);
}

.landing_cards_menu_title {
    font-size: 1.15rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    color: #2c3e50;
    letter-spacing: 0.5px;
    text-align: left;
}

.landing_cards_menu_info {
    font-size: .9rem;
    text-align: left;
    padding: 0.25rem;
    line-height: 1.6;
    color: black;
}

@media (max-width: 768px) {
    .landing_cards {
        grid-template-columns: 1fr;
        gap: 1rem;
        /* Reduced from 2rem */
        padding: 0 1.5rem;
    }

    .flip-card {
        width: 100%;
        height: 11rem;
    }
}

@media (max-width: 820px) {
    .landing_bg_services {
        display: none;
    }
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .landing_bg_header {
        margin: 0 3rem;
        font-size: 3rem;
    }

    .landing_bg_info {
        margin: 1rem 3rem;
        width: 80vw;
        font-size: 1.2rem;
    }

    .landing_bg_services {
        margin: 0;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .landing_bg_services_stack {
        width: 25%;
        height: 8rem;
        margin-bottom: 2rem;
    }

    .landing_bg_services_stack_button:hover {
        width: 13rem;
    }

    .landing_qt_images {
        margin: 3rem;
    }

    .landing_qt_images_style {
        width: 28%;
        /* margin-bottom: 2rem; */
    }

    .landing_info_table {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .landing_info_table_info {
        width: 95%;
        margin: 2rem auto;
    }

    .landing_info_table_line {
        height: 28rem;
    }

    .landing_blocks {
        margin: 3rem;
    }

    .landing_blocks_block {
        width: 40%;
        padding: 1.5rem;
    }

    .landing_blocks_block_title {
        font-size: 1.5rem;
    }

    .landing_customers_grid {
        margin: 1rem 3rem;
    }

    .landing_customers_grid_block_img {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .landing_cards {
        margin: 0 3rem;
        flex-wrap: wrap;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .landing_bg {
        height: 35rem;
    }

    .landing_bg_header {
        margin: 0 1rem;
        font-size: 3rem;
    }

    .landing_bg_sub_header {
        font-size: 2rem !important;
    }

    .landing_bg_info {
        margin: 1rem;
        width: 90vw;
        font-size: 1rem;
    }

    .landing_bg_services {
        display: none;
        height: 55%;
        margin: 0 1rem;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .landing_bg_services_stack {
        width: 45%;
        height: 6rem;
        margin-bottom: 1rem;
    }

    .landing_bg_services_stack_button {
        padding: 0.5rem;
    }

    .landing_qt_images {
        margin: 2rem 2rem;
        /* flex-direction: column; */
        align-items: center;
    }

    .landing_info_table {
        padding: 0;
        align-items: center;
        flex-direction: column;
    }

    .landing_info_table_line {
        height: 1px;
        width: 80%;
        border-left: 0;
        border-top: 1px solid #000000;
    }

    .landing_blocks {
        margin: 2rem 1rem;
        flex-direction: column;
        align-items: center;
    }

    .landing_blocks_block {
        width: 80%;
        margin: 1rem 0;
    }

    .landing_customers_grid {
        margin: 1rem 1rem;
    }

    .landing_customers_grid_block_img {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .landing_customers_more {
        margin: 1rem;
    }

    .landing_cards {
        margin: 2rem 1rem;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }

    .flip-card {
        width: 80%;
        margin-bottom: 1rem;
    }
}