.mv_main {
    margin: 2rem 8rem;
    display: flex;
    gap: 6rem;
    align-items: center;
}

.mv_main_img {
    width: 22rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.mv_main_info_title {
    font-size: 2rem;
    font-weight: 500;
}

.mv_main_info_para {
    margin-top: 0.5rem;
    color: #183D3D;
}

.mv_main_info_divider {
    margin: 1.5rem 3rem;
    height: 0.1rem;
    background-color: #A4FBC4;
}

.mv_main_info_points_point {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.mv_main_info_check {
    width: 2rem;
}

.mv_main_info_points_point_line {
    color: #3c3c3c;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .mv_main {
        margin: 2rem 3rem;
        gap: 3rem;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .mv_main {
        margin: 1rem;
        flex-direction: column;
    }

    .mv_main_vision {
        flex-direction: column-reverse;
    }
}