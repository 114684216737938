.testimonial-container {
    max-width: 1200px; /* Increased to accommodate side-by-side layout */
    margin: 0 auto;
    padding: 100px;
    font-family: Arial, sans-serif;
}

.testimonial-content {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Changed to two equal columns */
    gap: 48px;
    align-items: center;
}

.testimonial-image-container {
    position: relative;
    height: 500px;
    width: 100%;
}

.testimonial-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: bottom;
}

.testimonial-img {
    width: 80%;
    height: 80%;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
}

.testimonial-text {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    padding: 32px;
    text-align: left; /* Align text to left */
}

.testimonial-name {
    font-size: 38px;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 8px;
}

.testimonial-designation {
    font-size: 18px;
    color: #666;
    margin-bottom: 24px;
}

.testimonial-quote {
    font-size: 18px;
    color: #4a4a4a;
    line-height: 1.6;
    margin-bottom: 32px;
}

.quote-word {
    display: inline-block;
}

.testimonial-nav-buttons {
    display: flex;
    gap: 16px;
    margin-top: 24px;
}

.nav-button {
    height: 48px;
    width: 48px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.nav-button:hover {
    transform: translateY(-2px) rotate(12deg);
    background-color: #e8e8e8;
}

.nav-icon {
    width: 24px;
    height: 24px;
    color: #333;
}

/* Added media query for responsive design */
@media (max-width: 768px) {
    .testimonial-content {
        grid-template-columns: 1fr; /* Stack on mobile */
        gap: 32px;
    }

    .testimonial-image-container {
        height: 400px;
    }

    .testimonial-container {
        padding: 24px;
    }
}