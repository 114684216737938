.error_page {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error_page_error {
    font-size: 6rem;
    font-weight: bolder;
    color: #FF0000;
}

.error_page_img {
    margin: 1rem 0;
    width: 10rem;
}

.error_page_not_found {
    font-size: 4rem;
}

.error_page_text {
    font-size: 2rem;
}