.header {
    background-color: #F8F9F9;
    border-bottom: #282c34 solid 1px;
    font-size: 1rem;
    position: sticky;
    z-index: 1000;
    top: 0;
}

.header_main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 4rem 0.5rem 4rem;
}

.header_logo_div {
    cursor: pointer;
}

.header_logo {
    height: 4.5rem;
    pointer-events: none;
}

.header_components {
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header_toggle {
    display: none;
}

.header_content_item {
    color: #134B70;
    font-size: 1rem;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
}

.header_content_item:hover {
    color: #134B70;
    border-bottom: 1px solid #FF0000;
}

@keyframes blink-btn {
    0% {
        content: url("../images/header/user-hover.webp");
    }

    50% {
        content: url("../images/header/user.webp");
    }

    100% {
        content: url("../images/header/user-hover.webp");
    }
}

@-webkit-keyframes blink-btn {
    0% {
        content: url("../images/header/user-hover.webp");
    }

    50% {
        content: url("../images/header/user.webp");
    }

    100% {
        content: url("../images/header/user-hover.webp");
    }
}

.header_content_item_btn {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #405D72;
    border-radius: 5px;
    margin-left: 2rem;
    padding: 0.5rem 2rem;

    display: flex;
    align-items: center;
}

.header_content_item_btn:hover {
    color: #405D72;
    border-bottom: 1px solid #405D72;
    background-color: #d6d6d6;
    padding: 0.5rem 2rem;
}

.header_content_item_btn_img {
    width: 1.2rem;
    margin-right: 1rem;

    -webkit-animation: blink-btn 3.0s linear infinite;
    -moz-animation: blink-btn 3.0s linear infinite;
    -ms-animation: blink-btn 3.0s linear infinite;
    -o-animation: blink-btn 3.0s linear infinite;
    animation: blink-btn 3.0s linear infinite;
}

.header_content_item:hover .header_dropdown {
    display: flex;
    color: #000000;
}

.header_dropdown {
    display: none;
    width: 15rem;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    background-color: #ffffff;
    border-bottom: #282c34 solid 1px;
    z-index: 1;
    top: 2rem;
    left: 0;
}

.header_dropdown_content_item {
    color: #134B70;
    text-decoration: none;
    cursor: pointer;
    padding: 1rem;
}

.header_dropdown_content_item:hover {
    background-color: #d6d6d6;
    border-bottom: 1px solid #405D72;
    border-radius: 5px;
    color: #405D72;
}

.header_content_item_arrow {
    font-size: 0.8rem;
}

.header_content_item:hover .header_content_item_arrow {
    color: #FF0000;
}

.header_toggle_menu {
    display: none;
}

.header_content_item_btn_sub {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #405D72;
    border-radius: 5px;
    padding: 0.5rem 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.header_content_item_btn_sub:hover {
    color: #405D72;
    border-bottom: 1px solid #405D72;
    background-color: #d6d6d6;
    padding: 0.5rem 2rem;
}

.header_content_item_btn_sub:hover .header_content_item_btn_img {
    content: url("../images/header/user-hover.webp");
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .header_logo {
        margin: 1rem 0;
    }

    .header_main {
        margin: 0 2rem;
    }

    .header_components {
        width: 75vw;
    }

    .header_content_item {
        padding: 0.5rem;
    }

    .header_content_item_btn {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .header_logo {
        height: 3rem;
        margin: 1rem 0;
    }

    .header_components {
        display: none;
    }

    .header_toggle {
        display: block;
        font-size: 2rem;
        cursor: pointer;
        color: black;
    }

    .header_toggle_icon {
        width: 2rem;
    }

    .header_main {
        margin: 0 1rem;
    }

    .header_toggle_menu {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        position: absolute;
        top: 5rem;
        right: 0;
        width: 93%;
        z-index: 1000;
        padding: 1rem;
        border-bottom: 1px solid #282c34;
    }

    .header_dropdown {
        width: 100%;
        padding: 0rem;
        position: static;
        box-shadow: none;
        border: none;
        background-color: #d6d6d6;
    }

    .header_dropdown_content_item {
        margin: 0 0;
    }

    .header_dropdown_content_item:hover {
        background-color: #FFFFFF;
    }

    .header_content_item_btn {
        display: none;
    }
}