/* .App {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
} */

.app_sub_title {
  font-size: 3rem;
  font-weight: 600;
  margin: 1rem 8rem;
}

.app_paragraph {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 3rem auto;
  text-align: center;
  color: #2d3436;
  max-width: 79%;
  padding: 0rem 1.5rem;
  letter-spacing: 0.03em;
  font-weight: 400;
  background: linear-gradient(to right bottom, #ffffff, #f8f9fa);
  border: none;
  border-radius: 16px;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.05),
    0 6px 6px rgba(0, 0, 0, 0.02);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app_paragraph_two {
  margin: 1rem 8rem;
  text-align: justify;
}

.app_link {
  text-decoration: none;
  color: blueviolet;
}

.app_link:hover {
  color: blue;
}

/* MoreCustomers.js */
.customers_div {
  margin: 0 8rem;
  display: flex;
  justify-content: center;
}

.customers_dev_img {
  width: 80rem;
}

.uppercase {
  text-transform: uppercase;
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
  .app_sub_title {
    margin: 2rem 3rem;
  }

  .app_paragraph {
    margin: 1rem 3rem;
    font-size: 1.2rem;
  }

  .customers_div {
    margin: 1rem 0;
  }

  .customers_dev_img {
    width: 60rem;
  }

  .about_img {
    width: 50rem;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .app_sub_title {
    margin: 1rem;
    font-size: 1.5rem;
  }

  .app_paragraph {
    margin: 1rem 1rem;
    font-size: 1rem;
    max-width: 100%;
  }

  .app_paragraph_two {
    margin: 1rem;
  }

  .customers_dev_img {
    width: 22rem;
  }

  .about_img {
    width: 20rem;
  }
}