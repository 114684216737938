/* Improved ExpandableCard.css */
.jobs-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}



@media (max-width: 900px) {
    .jobs-container {
        grid-template-columns: 1fr; /* switch to single column on smaller screens */
    }
}


.jobs-title {
    font-size: 2.5rem;
    color: #1f1f1f;
    margin-bottom: 2rem;
    text-align: left;
    font-weight: 700;
}

.job-card {
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    min-width: 600px;
}

.job-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.job-card-content {
    flex: 1;
}

.job-card-title {
    font-size: 1.1rem;
    color: #222;
    margin: 0 0 0.5rem;
    font-weight: 700;
}

.job-card-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.job-card-meta span {
    font-size: 0.9rem;
    color: #666;
}

.view-details-button {
    background: #f8f9fa;
    border: 2px solid #e9ecef;
    padding: 0.7rem 1.4rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    color: #495057;
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.view-details-button:hover {
    background: #eee;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.55);
    z-index: 100;
}

.modal-container {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    padding: 1rem;
}

.modal-content {
    background: #fff;
    border-radius: 1rem;
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 102;
}

.modal-title {
    font-size: 1.75rem;
    color: #222;
    margin: 0;
}

.modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
    padding: 0.25rem 0.75rem;
}

.modal-close:hover {
    color: #555;
}

.modal-details {
    padding: 1.5rem;
}

.job-meta {
    margin-bottom: 1.5rem;
}

.job-meta p {
    margin: 0.5rem 0;
    color: #555;
}

.job-description {
    white-space: pre-wrap;
    color: #333;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    word-wrap: break-word;
    word-break: break-word;
}

.whatsapp-button {
    background: #4c4c4c;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    width: 100%;
    justify-content: center;
}

.whatsapp-button:hover {
    background: #222;
}

.whatsapp-icon {
    fill: currentColor;
    width: 1.2rem;
    height: 1.2rem;
}

@media (max-width: 600px) {
    .job-card {
        flex-direction: column;
        align-items: flex-start;
        min-width: auto;
    }

    .view-details-button {
        margin-top: 1rem;
        width: 100%;
    }

    .job-card-meta {
        margin-bottom: 0.75rem;
    }
}
