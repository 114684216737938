.reach_map {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.reach_map iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.reach_qr {
    margin: 3rem 30rem;
    padding: 3rem;
    border: 5px solid #000000;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reach_qr_code {
    height: 10rem;
}

.reach_qr_text {
    margin-top: 2rem;
    font-size: 1.5rem;
}

.reach_button_div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.reach_button {
    background-color: #283747;
    color: #FFFFFF;
    border-radius: 5px;
    border: 2px solid #283747;
    width: 20%;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.app_paragraph_two {
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 3rem auto;
    text-align: center;
    color: #2d3436;
    max-width: 70%;
    padding: 2rem 2.5rem;
    letter-spacing: 0.03em;
    font-weight: 700;
    background: linear-gradient(to right bottom, #ffffff, #f8f9fa);
    border: none;
    border-radius: 16px;
    box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.05),
        0 6px 6px rgba(0, 0, 0, 0.02);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app_paragraph_two:hover {
    transform: translateY(-2px);
    box-shadow:
        0 15px 30px rgba(0, 0, 0, 0.08),
        0 10px 10px rgba(0, 0, 0, 0.04);
}

.reach_button:hover {
    background-color: #00000000;
    color: #283747;
}

.reach_grid_row {
    display: flex;
    gap: 2rem;
}

.reach_grid {
    margin: 2rem 10rem;
}

.reach_grid_box {
    width: 50%;
    margin-bottom: 2rem;
    padding: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reach_grid_box_img {
    width: 5rem;
}

.reach_grid_box_country {
    margin-top: 0.5rem;
    font-size: 1.3rem;
    color: #020276;
}

.reach_grid_box_title {
    font-size: 1.5rem;
    font-weight: 500;
}

.reach_grid_box_hyper_img {
    width: 1rem;
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .reach_qr {
        margin: 3rem;
    }

    .reach_grid {
        margin: 1rem 2rem;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .reach_button {
        width: 40%;
    }

    .reach_grid {
        margin: 1rem;
    }

    .reach_grid_row {
        flex-direction: column;
        gap: 0rem;
    }

    .reach_grid_box {
        width: 100%;
        margin-bottom: 1rem;
    }

    .app_paragraph_two {
        max-width: 90%;
        padding: 1rem;
    }
}