/* General Outer Styling */
.about-section {
  padding: 60px 20px;
  /* Increase vertical spacing for modern look */

  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.8;
  max-width: 1450px;
  /* Reduced for tighter design */
  margin: auto;
}

.about-us-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0f4c75;
}

/* Flex Rows for Content and Images */
.about-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  /* Bigger spacing between sections */
}

/* Reverse the row for alternating layouts */
.about-row.reverse {
  flex-direction: row-reverse;
  /* Alternates image placement */
}

/* Content Styling */
.about-content {
  width: 60%;
  padding: 0 30px;
  /* Add some breathing room between text and image */
}

.about-content h2 {
  font-size: 2.2rem;
  /* Slightly larger font size for modern heading */
  font-weight: bold;
  color: #0f4c75;
  /* A modern blue tone */
  margin-bottom: 20px;
}

.about-content p {
  font-size: 1.2rem;
  /* Modern readable size */

  margin-bottom: 10px;
}

.about-content b {
  font-weight: bold;
  color: #0f4c75;
}

/* Image Container for About Image */
.about-image {
  width: 30%;
  /* Image and content are 50% each */
  text-align: center;
}

.about-image img {
  width: 100%;
  /* Full width of its container */
  max-width: 500px;
  /* Prevent overly large images */
  height: auto;
  /* Maintain aspect ratio */
  max-height: 400px;
  /* Set a maximum height for consistent size */
  border-radius: 15px;
  /* Slightly rounded corners for modern look */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  /* Soft shadow for depth */
  object-fit: cover;
  /* Ensures images scale uniformly if cropped */
  transition: transform 0.3s ease;
  /* Add hover interaction */
}

.about-image img:hover {
  transform: scale(1.05);
  /* Slight hover zoom effect */
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 768px) {
  .about-image img {
    max-width: 100%;
    /* Scale down images for smaller screens */
    max-height: 250px;
    /* Reduce height on mobile for better fit */
  }

  .about-row {
    margin: 0;
    flex-direction: column;
  }

  .about-content {
    width: 100%;
  }

  .about-image {
    width: 100%;
    margin: 2rem 0;
  }

  .about-row.reverse {
    flex-direction: column-reverse;
    /* Alternates image placement */
  }
}