/* Container for the whole layout */
.card-columns-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
}

/* Announcement Section */
.announcement-section {
    background-color: #3182ce;
    color: white;
    border-radius: 12px;
    padding: 30px;
    margin-bottom: 40px;
}

.announcement-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 16px;
}

.announcement-description {
    font-size: 1.125rem;
    margin-bottom: 24px;
}

.announcement-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.training-details {
    font-size: 1.125rem;
    font-weight: 600;
}

.training-details span {
    font-weight: normal;
}

.register-btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: white;
    color: #3182ce;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.register-btn:hover {
    background-color: #edf2f7;
}

/* Cards Section */
.card-columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
}

/* Individual Card */
.card {
    position: relative;
    background-color: #2d3748;
    color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 0 20px rgba(49, 130, 206, 0.5);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), 0 0 40px rgba(49, 130, 206, 0.8);
    transform: translateY(-10px);
}

.card:hover {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.card:hover .card-overlay {
    opacity: 1;
}

.card-content {
    position: relative;
    padding: 24px;
    z-index: 10;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 16px;
}

.card-description {
    font-size: 1rem;
    margin-bottom: 12px;
}

.card-date {
    font-size: 0.875rem;
    font-weight: 600;
    color: #e2e8f0;
    margin-bottom: 16px;
}

.card-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3182ce;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
    float: right;
}

.card-btn:hover {
    background-color: #2b6cb0;
}


@media (min-width: 768px) {
    .card-columns {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1023px) {
    .card-columns {
        grid-template-columns: repeat(4, 1fr);
    }
}