.footer_main {
    padding: 2rem 8rem 0 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #212121;
    color: #FFFFFF;
}

.footer_qtforum {
    margin: 2rem 8rem;
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #CCCCCC;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.footer_qtforum_info {
    font-size: 1.5rem;
}

.footer_qtforum_link {
    text-decoration: none;
}

.footer_qtforum_btn {
    width: 15rem;
    margin-left: 3rem;
    padding: 1rem;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    color: #FFFFFF;
    background-color: #183D3D;
    border-bottom: 1px solid #183D3D;
    border-radius: 10px;
}

.footer_qtforum_btn:hover {
    color: #183D3D;
    border-bottom: 1px solid #183D3D;
    background-color: #d6d6d6;
}

.footer_idea {
    padding: 1rem 8rem;
    display: flex;
    justify-content: space-between;
    background-color: #183D3D;
    color: #FFFFFF;
}

.footer_idea_title {
    font-size: 3rem;
    font-weight: 400;
}

.footer_idea_info {
    text-align: right;
    font-size: 3rem;
    font-weight: 100;
}

.footer_left {
    width: 35%;
}

.footer_social {
    padding: 1rem 40rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #183D3D;
}

.footer_social_img {
    width: 1.5rem;
    object-fit: cover;
}

.footer_copyright {
    padding-top: 2rem;
    text-align: center;
    background-color: #212121;
    color: #FFFFFF;
}

.footer_title {
    margin-left: 2rem;
    font-size: 1.8rem;
    font-weight: bold;
}

.footer_info_div {
    display: flex;
    align-items: center;
}

@keyframes blink_info {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.footer_loc_img {
    cursor: pointer;
    animation: blink_info 2s infinite;
    /* 1 second per blink, repeats infinitely */
}

.footer_info_img {
    height: 1rem;
    margin: 0.5rem 1rem 0 0;
}

.footer_info_link {
    text-decoration: none;
    color: #FFFFFF;
}

.footer_info_link:hover {
    color: blue;
}

.footer_location {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: start;
}

.footer_address {
    width: 47%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.footer_address_div {
    display: flex;
    flex-direction: column;
}

.footer_address_qr {
    /* margin-left: 2rem; */
    width: 7rem;
    height: 7rem;
}

.footer_kannada {
    font-size: 1.6rem;
    font-weight: bolder;
}

.footer_address_info {
    width: 100%;
}

.footer_flags {
    width: 1rem;
}

/* Media Query for small screen desktop or laptops */
@media (max-width: 1280px) {
    .footer_address {
        width: 60%;
    }

    .footer_social {
        padding: 1rem 32rem;
    }
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .footer_qtforum {
        margin: 2rem 3rem;
    }

    .footer_idea {
        padding: 2rem 3rem;
    }

    .footer_idea_title {
        font-size: 2rem;
    }

    .footer_social {
        padding: 1rem 24rem;
        flex-wrap: nowrap;
    }

    .footer_main {
        padding: 2rem 3rem;
    }

    .footer_left {
        width: 30%;
    }

    .footer_idea_info {
        font-size: 2.5rem;
    }

    .footer_kannada {
        font-size: 1.2rem;
    }
}

/* Media Quary for smaller tabs */
@media (max-width: 912px) {
    .footer_social {
        padding: 1rem 18rem;
    }

    .footer_address {
        width: 70%;
    }

    .footer_title {
        font-size: 1.5rem;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .footer_qtforum {
        flex-direction: column;
        margin: 1rem;
        padding: 2rem;
    }

    .footer_qtforum_btn {
        margin-top: 1rem;
        margin-left: 0;
    }

    .footer_qtforum_btn {
        padding: 1rem;
    }

    .footer_idea {
        font-size: 2rem;
        flex-direction: column;
    }

    .footer_idea_info {
        margin-top: 1rem;
        font-size: 2rem;
        text-align: left;
    }

    .footer_main {
        padding: 1rem;
        flex-direction: column-reverse;
    }

    .footer_social {
        /* width: 100%; */
        padding: 1rem 4rem;
    }

    .footer_address {
        width: 100%;
        flex-direction: column;
    }

    .footer_left {
        width: 100%;
        margin-top: 2rem;
    }

    .footer_copyright {
        font-size: 0.8rem;
    }

    .footer_title {
        font-size: 1.5rem;
    }

    .footer_address_qr {
        margin: 1rem 0 0 0;
        width: 100%;
        height: auto;
    }
}