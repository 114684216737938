:root {
  --primary-color: #183D3D;
  --primary-hover: #0f2828;
  --secondary-color: #183D3D;
  --success-color: #059669;
  --error-color: #dc2626;
  --background-color: #f8fafc;
  --card-background: #ffffff;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --border-color: #e2e8f0;
  --input-background: #f8fafc;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  --radius-sm: 8px;
  --radius-md: 12px;
  --radius-lg: 16px;
}

/* Base styles */
.visitor_interview_section {
  min-height: 100vh;
  padding: 3rem 2rem;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
}

.visitor_paragraph {
  font-size: 1rem;
  line-height: 1.5;
  margin: 3rem auto;
  text-align: center;
  color: #2d3436;
  max-width: 85%;
  padding: 1rem 1.5rem;
  letter-spacing: 0.03em;
  font-weight: 500;
  background: linear-gradient(to right bottom, #ffffff, #f8f9fa);
  border: none;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.visitor_paragraph b {
  font-size: 1.5rem;
  color: var(--primary-color);
  display: block;
  margin-bottom: 1.25rem;
  letter-spacing: -0.02em;
}

.visitor_form {
  max-width: 700px;
  margin: 0 auto;
  perspective: 1000px;
}

.visitor_form_div {
  background: var(--card-background);
  padding: 3rem;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  animation: formAppear 0.6s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.visitor_form_div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--primary-color);
}

.visitor_form_div_sub {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.visitor_form_span {
  flex: 1;
  min-width: 200px;
  position: relative;
}

.visitor_form_span_big {
  width: 100%;
}

.visitor_form_label {
  display: block;
  font-size: 0.95rem;
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.visitor_form_input,
.visitor_form select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--radius-md);
  font-size: 0.9rem;
  background-color: var(--input-background);
  transition: all 0.3s ease;
  color: var(--text-primary);
}

.visitor_form_input:hover,
.visitor_form select:hover {
  border-color: var(--primary-color);
  background-color: #fff;
}

.visitor_form_input:focus,
.visitor_form select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 4px rgba(24, 61, 61, 0.15);
  background-color: #fff;
}

.visitor_form_desc {
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--radius-md);
  font-size: 0.9rem;
  background-color: var(--input-background);
  transition: all 0.3s ease;
  color: var(--text-primary);
}

.visitor_form_purpose_check {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: left;
  align-items: last baseline;
  text-align: left;
  font-size: 0.9rem;
  margin: 0.5rem;
  padding: 0.6rem 1rem;
  background-color: #fff;
  border-radius: 100px;
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  line-height: 1.4;
  gap: 0rem;
}

.visitor_form_skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--input-background);
  border-radius: var(--radius-lg);
  border: 2px solid var(--border-color);
}

.visitor_form_skill_check {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  gap: 0.75rem;
  padding: 0.6rem 1rem;
  background-color: #fff;
  border-radius: 100px;
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
}

.visitor_form_skill_check:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--primary-color);
}

.visitor_form_skill_check input[type="checkbox"] {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.visitor_form_skill_check input[type="checkbox"]:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.visitor_form_skill_check input[type="checkbox"]:checked::before {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 0.85rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visitor_form_submit {
  background-color: var(--primary-color);
  margin-top: 1.5rem;
  border: none;
  color: #FFFFFF;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1.5rem auto;
}

.visitor_form_submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.visitor_form_submit:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.visitor_form_submit:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(24, 61, 61, 0.3);
}

.visitor_form_submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.dialog {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.25rem 2.5rem;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-lg);
  z-index: 1000;
  animation: slideDown 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  max-width: 90%;
}

.dialog.success {
  background: linear-gradient(135deg, #059669 0%, #047857 100%);
  color: white;
}

.dialog.error {
  background: linear-gradient(135deg, #dc2626 0%, #b91c1c 100%);
  color: white;
}

/* Enhanced Animations */
@keyframes formAppear {
  0% {
    opacity: 0;
    transform: rotateX(-5deg) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: rotateX(0) translateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 768px) {
  .visitor_form_div {
    padding: 2rem 1.5rem;
  }

  .visitor_form_div_sub {
    flex-direction: column;
    gap: 1.5rem;
  }

  .visitor_paragraph {
    padding: 1.5rem;
    margin: 1rem;
    font-size: 0.95rem;
  }

  .visitor_paragraph b {
    font-size: 1.25rem;
  }
}
