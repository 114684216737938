.landing_teams {
    margin: 2rem 0;
}

.team_members {
    margin: 0 8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.team_members_individual {
    width: 23%;
    margin-bottom: 2rem;
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.team_members_individual_img_div {
    position: relative;
}

.team_members_individual_img {
    width: 100%;
}

.team_members_individual_logo {
    width: 1.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.team_members_individual_name {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
}

.team_members_individual_designation {
    font-size: 0.8rem;
}

.team_members_individual_description {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    max-height: 3rem;
    transition: max-height 0.5s ease, white-space 0.3s ease;
}

.team_members_individual_description.expanded {
    max-height: 16rem;
    white-space: normal;
}

.team_members_individual {
    transition: transform 0.3s ease;
}

.team_openings {
    margin: 3rem 0;
    display: flex;
    justify-content: space-around;
}

.team_openings_button {
    background-color: #000000;
    border: 2px solid #000000;
    color: #FFFFFF;
    border-radius: 10px;
    text-decoration: none;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
}

.team_openings_button:hover {
    background-color: #00000000;
    border: 2px solid #000000;
    color: #000000;
}

.team_img {
    width: 100%;
}

/* Media Query for small screen desktop */
@media (max-width: 1280px) {
    .team_members_individual_description.expanded {
        max-height: 18rem;
    }
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .team_members {
        margin: 4rem 3rem 0 3rem;
    }

    .team_members_individual {
        width: 48%;
    }

    .team_members_individual_description {
        max-height: 14rem;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .team_members {
        margin: 2rem 1rem 0 1rem;
    }

    .team_members_individual {
        width: 48%;
    }

    .team_members_individual_name {
        font-size: 1rem;
    }

    .team_members_individual_description {
        display: none;
    }
}