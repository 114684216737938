.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.testimonials__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.testimonials__images {
    flex: 1;
    display: flex;
    justify-content: center;
}

.testimonials__image-wrapper {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #ddd;
}

.testimonials__content {
    flex: 1;
    text-align: center;
}

.testimonials__name {
    font-weight: bold;
    font-size: 1.5rem;
}

.testimonials__role {
    color: #666;
    margin: 5px 0;
}

.testimonials__quote {
    font-size: 1rem;
    line-height: 1.5;
    margin: 10px 0;
}

.testimonials__nav {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.testimonials__button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
}

.testimonials__arrow {
    font-size: 2rem;
    color: #333;
}

.empty-state {
    font-size: 1.2rem;
    color: #999;
    text-align: center;
}

.accent-line {
    width: 100px;
    height: 4px;
    background: #333;
    margin: 10px auto;
    border-radius: 2px;
}

.card-header {
    text-align: center;
    margin-bottom: 20px;
}

.card-title {
    font-size: 2rem;
    font-weight: bold;
}
