/* Container styles */
.spotlight-container {
    position: relative;
    height: 70svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    overflow: hidden;
    cursor: none;
}

/* Dot pattern background */
.dot-pattern {
    position: absolute;
    inset: 0;
    background-image: radial-gradient(circle, #1f2937 2px, transparent 2px);
    background-size: 16px 16px;
    pointer-events: none;
}

/* Spotlight effect */
.spotlight-effect {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-image: radial-gradient(circle, #065f46 2px, transparent 2px);
    background-size: 16px 16px;
    pointer-events: none;
}

.spotlight-container:hover .spotlight-effect {
    opacity: 1;
    filter: brightness(1.5);
}

/* Spotlight center dot */
.spotlight-dot {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #ca8a04;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s, transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.spotlight-container:hover .spotlight-dot {
    opacity: 1;
    filter: brightness(1.5);
}

/* Content container */
.content {
    position: relative;
    z-index: 20;
    width: 100%;
}

/* Highlight component styles */
.highlight {
    position: relative;
    display: inline-block;
    padding: 0 4px 4px;
    border-radius: 8px;
    background: linear-gradient(to right, #6366f1, #a855f7);
    animation: highlight-reveal 2s linear 0.5s forwards;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 0% 100%;
}

@keyframes highlight-reveal {
    from {
        background-size: 0% 100%;
    }

    to {
        background-size: 100% 100%;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .spotlight-container {
        background-color: black;
    }

    .dot-pattern {
        background-image: radial-gradient(circle, #1f2937 2px, transparent 2px);
    }

    .spotlight-effect {
        background-image: radial-gradient(circle, white 2px, transparent 2px);
    }

    .highlight {
        background: linear-gradient(to right, #6366f1, #a855f7);
    }
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
    .spotlight-container {
        height: 40svh;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .spotlight-container {
        height: 60svh;
    }
}