/* Root variables for consistent theming */
:root {
  --primary-color: #422b6f;
  --primary-hover: #4d3380;
  --background-light: #ffffff;
  --background-dark: #183D3D;
  --text-dark: #000000;
  --text-light: #FFFFFF;
  --spacing-unit: clamp(0.5rem, 1vw, 1rem);
  --container-padding: clamp(1rem, 5vw, 8rem);
  --border-radius: 8px;
  --transition-standard: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Container layout */
.career_system {
  max-width: 1440px;
  margin: 0 auto;
  padding: var(--spacing-unit) var(--container-padding);
}

.career_system_time {
  font-size: clamp(1.5rem, 3vw, 2rem);
  line-height: 1.4;
}

/* Image container */
.career_img_div {
  display: flex;
  justify-content: center;
  margin: clamp(1rem, 3vw, 2rem) auto;
  width: 100%;
}

.career_img {
  width: min(100%, 600px);
  height: auto;
  object-fit: contain;
}

/* Career menu grid */
.career_menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  gap: clamp(1rem, 2vw, 2rem);
  padding: var(--spacing-unit) var(--container-padding);
  max-width: 1440px;
  margin: 0 auto;
}

/* Card styling */
.career_menu_card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: clamp(1rem, 2vw, 1.5rem);
  border-radius: var(--border-radius);
  border: 2px solid var(--primary-color);
  color: var(--text-dark);
  text-decoration: none;
  transition: var(--transition-standard);
  background: var(--background-light);
}

.career_menu_card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(66, 43, 111, 0.12);
  border-color: var(--primary-hover);
  background-color: #f8f9fa;
}

.career_menu_card_div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.career_menu_card_div_title {
  font-weight: 600;
  color: var(--primary-color);
  font-size: clamp(1rem, 1.2vw, 1.25rem);
}

.career_menu_card_div_location {
  font-size: clamp(0.875rem, 1vw, 1rem);
  color: #666;
}

/* Subscribe section */
.career_subscribe_main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));
  gap: clamp(2rem, 4vw, 4rem);
  padding: clamp(2rem, 4vw, 4rem);
  margin: var(--spacing-unit) auto;
  max-width: 1440px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border-radius: calc(var(--border-radius) * 2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: var(--transition-standard);
}

.career_paragraph {
  padding: clamp(1.5rem, 3vw, 3rem);
  font-size: clamp(.6rem, 1.2vw, 1.5rem);
  line-height: 1.2;
  background: linear-gradient(135deg, #ffffff, #f8f9fa);
  border-radius: var(--border-radius);
  box-shadow: 0 10px 20px rgba(66, 43, 111, 0.06);
  transition: var(--transition-standard);
}

.career_subscribe {
  padding: clamp(2rem, 4vw, 3.5rem);
  width: 100%;
  min-height: 1.2;
  background: linear-gradient(145deg, #1f4f4f, var(--background-dark));
  color: var(--text-light);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  transition: var(--transition-standard);
  text-decoration: none;
}

.career_subscribe_title {
  font-size: clamp(1.5rem, 3vw, 3rem);
  font-weight: 300;
  text-align: center;
  line-height: 1.5;
}

.career_subscribe_img {
  width: clamp(2.5rem, 4vw, 4rem);
  height: auto;
  animation: blink 2s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

/* Focus and accessibility improvements */
.career_menu_card:focus-visible,
.career_subscribe:focus-visible {
  outline: 3px solid var(--primary-color);
  outline-offset: 2px;
}

/* Print styles */
@media print {

  .career_subscribe,
  .career_subscribe_img {
    display: none;
  }

  .career_menu_card {
    break-inside: avoid;
    border: 1px solid #000;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .career_subscribe_img {
    animation: none;
  }

  .career_menu_card,
  .career_subscribe,
  .career_paragraph {
    transition: none;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .career_paragraph {
    padding: 1rem;
    font-size: 1rem;
  }
}